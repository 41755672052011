<template>
	<div class="layout">
		<MainHeader></MainHeader>
		<div class="wrapper">
			<!-- 内容 -->
			<router-view />
		</div>
		<MainFooter></MainFooter>
	</div>
</template>

<script>
	import MainHeader from "../components/header.vue";
	import MainFooter from "../components/footer.vue";
	export default {
		name: "Home",
		components: {
			MainHeader,
			MainFooter,
		},
		data() {
			return {
			};
		},
		methods: {
		},
	};
</script>
