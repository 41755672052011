<template>
	<div class="main-header"
		:style="{backgroundImage:$route.path=='/home'?'url(' + imageUrl + ')':'url(' + imageUrl2 + ')'}">
		<!-- <a href="javascript:;" @click="resetSearch()" class="router-link-exact-active router-link-active">
			<img src="../assets/img/logo.png" />
		</a> -->
		<div class="right">
			<MainSearch v-if="$route.meta.alive" @changeSearch="changeSearch" @onSearch="handleSearch"
				:keyword="query.keyword" :searchData="searchData" :resultShow="resultShow"></MainSearch>
			<div v-for="(item,index) in navList" :key="item.id" @click="navItem(index,item)">
				<div class="nav-item" :class="$route.path==item.url?'active':''"
					v-if="index < 1 || (index >= 1 && index <=2 && power('verify-edit')) || (index == 3 && power('photo-edit'))">
					<i class="iconfont" v-html="item.iconfont"></i>
					<span>{{item.title}}</span>
				</div>
			</div>
			<el-link v-if="!token" class="white-btn" plain round href="http://insure.kuaizhipei.com"
				target="_blank">保险登录</el-link>
			<el-link v-if="!token" class="white-btn" plain round href="http://search.kuaizhipei.com"
				target="_blank">经销商登录</el-link>
			<el-dropdown v-if="token" @command="handleCommand">
				<span class="el-dropdown-link">
					{{userName}}<i class="el-icon-arrow-down el-icon--right"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item v-if="power('insuranceQuote')" command="12">保险定损</el-dropdown-item>
					<el-dropdown-item v-if="power('role')" command="10">角色管理</el-dropdown-item>
					<el-dropdown-item v-if="power('sysuser')" command="4">账户管理</el-dropdown-item>
					<el-dropdown-item v-if="power('user')" command="7">用户审核</el-dropdown-item>
          <el-dropdown-item v-if="power('user')" command="13">纠错信息</el-dropdown-item>
					<el-dropdown-item v-if="power('order')" command="8">用户订单</el-dropdown-item>
					<el-dropdown-item v-if="power('category')" command="5">分类管理</el-dropdown-item>
					<el-dropdown-item v-if="power('insure-price')" command="6">价格编辑</el-dropdown-item>
					<el-dropdown-item v-if="power('article')" command="9">公告管理</el-dropdown-item>
					<el-dropdown-item v-if="power('productSale')" command="11">销售信息</el-dropdown-item>
          <el-dropdown-item v-if="power('photo-edit')" command="14">图片审核</el-dropdown-item>
					<el-dropdown-item v-if="false" command="3">查询统计</el-dropdown-item>
					<el-dropdown-item command="2">修改密码</el-dropdown-item>
					<el-dropdown-item command="1">退出</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
		<!-- 登录弹窗 -->
		<el-dialog :visible.sync="dialogVisible" width="800px" :append-to-body="true" custom-class="login-dialog"
			top="30vh">
			<!-- <img class="login-img" src="../assets/img/login-img.jpg" /> -->
			<div class="right">
				<div class="title">登录</div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm">
					<el-form-item prop="user">
						<el-input prefix-icon="el-icon-user" placeholder="请输入账号" v-model="ruleForm.user"></el-input>
					</el-form-item>
					<el-form-item prop="password">
						<el-input prefix-icon="el-icon-lock" placeholder="请输入密码" type="password"
							v-model="ruleForm.password"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button class="main-btn" type="warning" @click="submitForm('ruleForm')">登录</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import http from "../http.js";
	import power from "../power.js";
	import commFunc from "@/commFunc";
	import MainSearch from "./search.vue";
	export default {
		components: {
			MainSearch
		},
		data() {
			return {
				imageUrl: require('@/assets/img/header-home.png'),
				imageUrl2: require('@/assets/img/header.png'),
				searchData: [],
				query: {
					page: 1,
					pagesize: 10,
					keyword: "",
					model: "",
					sort: "-id",
				},
				resultShow: false,
				activeIndex: '',
				navList: [{
						id: 1,
						iconfont: '&#xe612;',
						title: "首页",
						url: '/home'
					}, {
						id: 2,
						iconfont: '&#xe60e;',
						title: "审核信息",
						url: '/examine'
					}, {
						id: 3,
						iconfont: '&#xe611;',
						title: "数据统计",
						url: '/enterStatistics'
					}, {
						id: 4,
						iconfont: '&#xe8d3;',
						title: "图片编辑",
						url: '/sparesList'
					},
					// {
					// 	id: 4,
					// 	iconfont: '&#xe616;',
					// 	title: "账号管理"
					// }
				],
				token: "",
				userName: "",
				isVerify: false,
				isCategory: false,
				dialogVisible: false,
				ruleForm: {
					user: "",
					password: "",
				},
				rules: {
					user: [{
						required: true,
						message: "请输入正确的账号",
						trigger: "blur",
					}, ],
					password: [{
						required: true,
						message: "请输入正确的密码",
						trigger: "blur",
					}, ],
				},
			};
		},
		created() {
			this.init();
		},
		methods: {
			handleSearch(keyword) {
				// this.query.keyword = keyword;
				// this.query.page = 1;
				// this.getList();
				// this.tableShow = true
				this.$router.push({
					path: "/productList",
					query: {
						keyword
					}
				})
			},
			rateMul100(item) {
				commFunc.rateMul100(item)
			},
			changeSearch(keyword) {
				this.query.keyword = keyword;
				this.query.page = 1;
				http.fetchGet("/api/admin/product/alllist", this.query).then((res) => {
					if (res.data.code == 0) {
						let list = res.data.data.list
						list.forEach(item => {
							this.rateMul100(item);
						})
						this.searchData = list;
						this.total = res.data.data.total;
						if (this.searchData.length == 0) {
							this.resultShow = false
						} else {
							this.resultShow = true
						}
					} else if (res.data.code == 50008) {
						localStorage.clear();
						this.loginKey++;
						this.$message.error("登录信息已失效，请重新登录");
					}
				});
			},
			navItem(index, item) {
				this.activeIndex = index
				if (item.id == 1) {
					if (this.$route.path == '/home') {
						this.$emit('resetSearch')
					} else {
						this.$router.push('/home')
					}
				} else {
					this.$router.push(item.url)
				}
			},
			login() {
				this.$router.push('/login')
			},
			resetSearch() {
				if (this.$route.path == '/') {
					this.$emit('resetSearch')
				} else {
					this.$router.push('/')
				}
			},
			power(p) {
				return power(p);
			},
			init() {
				let token = localStorage.getItem("token");
				let userName = localStorage.getItem("name");
				if (token) {
					this.token = token;
				} else {
					this.token = "";
					console.log(this.$route.path)
					if (this.$route.path != '/home') {
						this.login()
					}
				}
				if (userName) {
					this.userName = userName;
				} else {
					this.userName = "";
				}
				this.isVerify = power('verify-edit')
				this.isCategory = power('category')
			},
			handleCommand(command) {
				if (command == 1) {
					this.$confirm("确定要退出吗?", "提示", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "warning",
						})
						.then(() => {
							this.token = "";
							this.userName = "";
							localStorage.clear();
							this.$router.push("/");
						})
						.catch(() => {});
				} else if (command == 2) {
					this.$router.push("/resetPassword")
				} else if (command == 3) {
					this.$router.push("/queryStatistics");
				} else if (command == 4) {
					this.$router.push("/account");
				} else if (command == 5) {
					this.$router.push("/category");
				} else if (command == 6) {
					this.$router.push("/priceEditing");
				} else if (command == 7) {
					this.$router.push("/user");
				} else if (command == 8) {
					this.$router.push("/order");
				} else if (command == 9) {
					this.$router.push("/articleEditing");
				} else if (command == 10) {
					this.$router.push("/role");
				} else if (command == 11) {
					this.$router.push("/productSale")
				} else if (command == 12) {
					this.$router.push("/assessment")
				} else if (command == 13) {
          this.$router.push("/reportError")
        } else if (command == 14) {
          this.$router.push("/uploadImgCheck")
        }
			},
		},
	};
</script>

<style lang="scss">
	//scss
</style>
