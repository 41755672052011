function accMul(arg1, arg2) {
    if(!arg1 || !arg2){
        return ''
    }
    var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length;
    } catch (e) {
        console.log(e)
    }
    try {
        m += s2.split(".")[1].length;
    } catch (e) {
        console.log(e)
    }
    return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
    );
}
function priceRound(num) {
    // return Math.round((num + Number.EPSILON) * 10) / 10;
    return Math.round(num);
}
function priceRound_1(num) {
    return Math.round((num + Number.EPSILON) * 10) / 10;
}
var commFunc = {
    rateDiv100(item) {
        item.rateInner = Number(item.rateInner) / 100;
        item.rateStore = Number(item.rateStore) / 100;
        item.rateStoreTax = Number(item.rateStoreTax) / 100;
        item.rateFleet = Number(item.rateFleet) / 100;
        item.rateFleetTax = Number(item.rateFleetTax) / 100;
        item.rateDiscountFleet = Number(item.rateDiscountFleet) / 100;
        item.rateEndUser = Number(item.rateEndUser) / 100;
        item.rateEndUserTax = Number(item.rateEndUserTax) / 100;
        item.ratePartner = Number(item.ratePartner) / 100;
        item.ratePartnerTax = Number(item.ratePartnerTax) / 100;
        item.rateAutoCity = Number(item.rateAutoCity) / 100;
        item.rateAutoCityTax = Number(item.rateAutoCityTax) / 100;
    },
    rateMul100(item) {
        item.rateInner = accMul(item.rateInner, 100);
        item.rateStore = accMul(item.rateStore, 100);
        item.rateStoreTax = accMul(item.rateStoreTax, 100);
        item.rateFleet = accMul(item.rateFleet, 100);
        item.rateFleetTax = accMul(item.rateFleetTax, 100);
        item.rateDiscountFleet = accMul(item.rateDiscountFleet, 100);
        item.rateEndUser = accMul(item.rateEndUser, 100);
        item.rateEndUserTax = accMul(item.rateEndUserTax, 100);
        item.ratePartner = accMul(item.ratePartner, 100);
        item.ratePartnerTax = accMul(item.ratePartnerTax, 100);
        item.rateAutoCity = accMul(item.rateAutoCity, 100);
        item.rateAutoCityTax = accMul(item.rateAutoCityTax, 100);
    },
    priceCalc(p) {
        let cost = p.priceCost;
        if (cost) {
            if (p.rateInner) {
                p.priceInner = priceRound_1(
                    cost * (1 + p.rateInner / 100)
                );
            } else {
                p.priceInner = ''
            }
            if (p.rateStore) {
                p.priceStore = priceRound(
                    cost * (1 + p.rateStore / 100)
                );
            } else {
                p.priceStore = ''
            }
            if (p.rateFleet) {
                // let rate = this.ruleForm.rateFleet;
                p.priceFleet = priceRound(
                    cost * (1 + p.rateFleet / 100)
                );
            } else {
                p.priceFleet = ''
            }
            if (p.rateFleetTax) {
                p.priceFleetTax = priceRound(
                    cost * (1 + p.rateFleetTax / 100)
                );
            } else {
                p.priceFleetTax = ''
            }
            if (
                p.rateDiscountFleet &&
                p.priceFleetTax
            ) {
                p.priceFleetTaxDiscount = priceRound(
                    p.priceFleetTax *
                    (1 - p.rateDiscountFleet / 100)
                );
            } else {
                p.priceFleetTaxDiscount = ''
            }
            if (p.rateEndUser && p.priceStore) {
                p.priceEndUser = priceRound(
                    p.priceStore *
                    (1 + p.rateEndUser / 100)
                );
            } else {
                p.priceEndUser = ''
            }
            if (
                p.rateEndUserTax &&
                p.priceStore
            ) {
                p.priceEndUserTax = priceRound(
                    p.priceStore *
                    (1 + p.rateEndUserTax / 100)
                );
            } else {
                p.priceEndUserTax = ''
            }
            if (p.ratePartner) {
                p.pricePartner = priceRound_1(
                    cost * (1 + p.ratePartner / 100)
                );
            } else {
                p.pricePartner = ''
            }
            if (
                p.rateAutoCity &&
                p.pricePartner
            ) {
                p.priceAutoCity = priceRound_1(
                    p.pricePartner *
                    (1 + p.rateAutoCity / 100)
                );
            } else {
                p.priceAutoCity = ''
            }
            if (
                p.rateAutoCityTax &&
                p.pricePartner
            ) {
                p.priceAutoCityTax = priceRound_1(
                    p.pricePartner *
                    (1 + p.rateAutoCityTax / 100)
                );
            } else {
                p.priceAutoCityTax = ''
            }

            if (p.rateStoreTax) {
                p.priceStoreTax = priceRound(
                    cost * (1 + p.rateStoreTax / 100)
                );
            } else {
                p.priceStoreTax = ''
            }
            if (p.ratePartnerTax) {
                p.pricePartnerTax = priceRound_1(
                    cost * (1 + p.ratePartnerTax / 100)
                );
            } else {
                p.pricePartnerTax = ''
            }
        }
    },
}
export default commFunc;
