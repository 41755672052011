<template>
	<div class="footer">
		<p><span class="first">玉韦耶克物联有限责任公司 技术支持</span><em>Copyright © {{year}} 版权所有 宝达集团</em></p>
		<p class="beian"><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">鲁ICP备2022035994号-2</a>
			<a class="beian-item" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37030602000461">
				<img src="https://yuweiyeke.oss-cn-zhangjiakou.aliyuncs.com/static/images/gongan.png" />
				<span>
					鲁公网安备 37030602000461号
				</span>
			</a>
			<el-button v-if="!token" class="white-btn" plain round @click="login">管理登录</el-button>
		</p>

	</div>
</template>

<script>
	let myDate = new Date();
	let tYear = myDate.getFullYear();
	export default {
		data() {
			return {
				year: tYear,
                token: "",
			}
		},
        mounted() {
            this.token = localStorage.getItem("token");
        },
		methods:{
			login() {
				this.$router.push('/login')
			},
		}
	}
</script>

<style>
</style>
