import axios from 'axios'
// import qs from 'qs'

axios.defaults.timeout = 500000;                        //响应时间
//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = '/';   //配置接口地址
if(process.env.NODE_ENV === "development"){
	// axios.defaults.baseURL = 'http://localhost:8001';   //配置接口地址
}

//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use((config) => {
  if(localStorage.getItem("token")){
    config.headers["token"] = localStorage.getItem("token")
  }
    //如果content-type='application/x-www-form-urlencoded;charset=UTF-8'，需要启用下面三行
     // if(config.method  === 'post'){
     //     config.data = qs.stringify(config.data);
     // }
    return config;
},(error) =>{
    console.log('错误的传参')
    return Promise.reject(error);
});

//返回状态判断(添加响应拦截器)
axios.interceptors.response.use((res) =>{
  if(res.data.code == -999){
    alert(res.data.desc)
  }
    //对响应数据做些事
    if(!res.data.success){
        return Promise.resolve(res);
    }
    return res;
}, (error) => {
    console.log('网络异常')
    return Promise.reject(error);
});

//返回一个Promise(发送post请求)
export function fetchPost(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(response => {
                resolve(response);
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error)
            })
    })
}
////返回一个Promise(发送get请求)
export function fetchGet(url, param) {
    return new Promise((resolve, reject) => {
        axios.get(url, {params: param})
            .then(response => {
                resolve(response)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export default {
    fetchPost,
    fetchGet,
}
