
export default {
  closePage: function (app) {
    //关闭子页面
    app.$store.state.tagsView.visitedViews.splice(app.$store.state.tagsView.visitedViews.findIndex(item => item.path === app.$route.path), 1)
    app.$router.push(app.$store.state.tagsView.visitedViews[app.$store.state.tagsView.visitedViews.length - 1].path)
  },
  today: function () {
    var time = (new Date).getTime();
    var yesterday = new Date(time);
    yesterday = yesterday.getFullYear() + "-" + (yesterday.getMonth() > 8 ? (yesterday.getMonth() + 1) : "0" + (yesterday.getMonth() + 1)) + "-" + (yesterday.getDate() > 9 ? (yesterday.getDate()) : "0" + (yesterday.getDate()));
    return yesterday
  },
  dayOffset: function (date, day) {
    var time = (new Date(date)).getTime() + 24 * 60 * 60 * 1000 * day;
    var yesterday = new Date(time);
    yesterday = yesterday.getFullYear() + "-" + (yesterday.getMonth() > 8 ? (yesterday.getMonth() + 1) : "0" + (yesterday.getMonth() + 1)) + "-" + (yesterday.getDate() > 9 ? (yesterday.getDate()) : "0" + (yesterday.getDate()));
    return yesterday
  },
  yesterday: function () {
    var time = (new Date).getTime() - 24 * 60 * 60 * 1000;
    var yesterday = new Date(time);
    yesterday = yesterday.getFullYear() + "-" + (yesterday.getMonth() > 8 ? (yesterday.getMonth() + 1) : "0" + (yesterday.getMonth() + 1)) + "-" + (yesterday.getDate() > 9 ? (yesterday.getDate()) : "0" + (yesterday.getDate()));
    return yesterday
  },
  lastWeekDay: function () {
    var time = (new Date).getTime() - 7 * 24 * 60 * 60 * 1000;
    var day = new Date(time);
    day = day.getFullYear() + "-" + (day.getMonth() > 8 ? (day.getMonth() + 1) : "0" + (day.getMonth() + 1)) + "-" + (day.getDate() > 9 ? (day.getDate()) : "0" + (day.getDate()));
    return day
  },
  //js精确相加函数
  accAdd: function (arg1, arg2) {
    var r1, r2, m;
    try {
      r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    return (arg1 * m + arg2 * m) / m;
  },
  //js精确相乘函数
  accMul: function (arg1, arg2) {
    var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
    try {
      m += s1.split(".")[1].length;
    } catch (e) {
      m = 0
    }
    try {
      m += s2.split(".")[1].length;
    } catch (e) {
      m = 0
    }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
  },
    //js精确相除函数
    accDiv: function (arg1, arg2) {
        var t1 = 0, t2 = 0, r1, r2;
        try {
            t1 = arg1.toString().split(".")[1].length;
        } catch (e) {
            t1 = 0;
        }
        try {
            t2 = arg2.toString().split(".")[1].length;
        } catch (e) {
            t2 = 0;
        }
        r1 = Number(arg1.toString().replace(".", ""));
        r2 = Number(arg2.toString().replace(".", ""));
        return (r1 / r2) * Math.pow(10, t2 - t1);
    },
  calcPrice(price, xichai){
      if(price==0 && !xichai){
          return 0;
      }
      let p = price ? price : xichai
    return p ? (this.accMul(p, 1.13)).toFixed(2) : '-'
  },
  calcPriceService(row){
      let p = row.priceEndUser ? row.priceEndUser : row.priceXichai
    return p ? (this.accMul(this.accMul(p, 0.95), 1.13)).toFixed(2) : '-'
  },
  calcAmount(loss){
    loss.changeAmount = loss.changeList.reduce((total, item) => {
      return this.accAdd(total, item.amount)
    }, 0)
    loss.laborAmount = loss.laborList.reduce((total, item) => {
      return this.accAdd(total, item.amount)
    }, 0)
    loss.accessoriesAmount = loss.accessoriesList.reduce((total, item) => {
      return this.accAdd(total, item.amount)
    }, 0)
      loss.changeAmount = loss.changeAmount.toFixed(2)
      loss.laborAmount = loss.laborAmount.toFixed(2)
        loss.accessoriesAmount = loss.accessoriesAmount.toFixed(2)
    loss.amount = this.accAdd(loss.changeAmount,
        this.accAdd(loss.laborAmount, loss.accessoriesAmount))
      loss.amount = loss.amount.toFixed(2)
  },
    calcBrandPrice(row) {
        if(row.brandPrice){
            return row.priceBrand
        }else if (row.priceEndUser){
            return (row.priceEndUser*1.13*1.05*0.75).toFixed(2)
        }else{
            return '-'
        }
    },
    calcSubFactoryPrice(row) {
        if(row.priceSubFactory){
            return row.priceSubFactory
        }else if (row.priceEndUser){
            return (row.priceEndUser*1.13*1.05*0.75*0.65).toFixed(2)
        }else{
            return '-'
        }
    },
    //format date from utc
    formatDate(date, format) {
        if (!date) return '';
        date = new Date(date);
        var map = {
            "M": date.getMonth() + 1, //月份
            "d": date.getDate(), //日
            "h": date.getHours(), //小时
            "m": date.getMinutes(), //分
            "s": date.getSeconds(), //秒
            "q": Math.floor((date.getMonth() + 3) / 3), //季度
            "S": date.getMilliseconds() //毫秒
        };
        format = format.replace(/([yMdhmsqS])+/g, function(all, t){
            var v = map[t];
            if(v !== undefined){
                if(all.length > 1){
                    v = '0' + v;
                    v = v.substr(v.length-2);
                }
                return v;
            }
            else if(t === 'y'){
                return (date.getFullYear() + '').substr(4 - all.length);
            }
            return all;
        });
        return format;
    }
}
